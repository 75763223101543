import { createPinia } from 'pinia';

const Pinia = createPinia();

export default {
  install: (app) => {
    app.config.silent = false;
    app.use(Pinia);
  },
};
